export default (url: string): void => {
	let sent = false
	const { viewerModel } = window
	if (!viewerModel?.experiments['specs.thunderbolt.useImgNotBeacon']) {
		try {
			sent = navigator.sendBeacon(url)
		} catch (e) {}
	}
	if (!sent) {
		new Image().src = url
	}
}
